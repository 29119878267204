<template>
  <div>

    <!-- Main Row -->
    <div class="row">

      <!-- Form -->
      <div class="col-md-12">
        <Card :title="'Detail Biaya Laboratorium'">
          <template v-slot:body>
            <div class="row align-items-center">

              <!-- Profile Image -->
              <div class="col-md-4">
                <div class="d-flex justify-content-center align-items-center image-container">
                  <div style='height: 350px; width: 350px; position: relative'>
                    <img
                      class="image"
                      src="/images/polyclinic-splash.svg"
                      @load="onImageLoad()">
                  </div>
                </div>
              </div>

              <!-- Table Data -->
              <div
                class="col"
                v-if="imgLoaded">
                <table class="table mb-3">
                  <tr>
                    <td style="width: 25%"><strong>Nama</strong></td>
                    <td>{{ data.name }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%"><strong>Harga</strong></td>
                    <td>Rp {{ parseInt(data.price).toLocaleString('id-ID') }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%"><strong>Deskripsi</strong></td>
                    <td>{{ data.description }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%"><strong>Kategori Lab</strong></td>
                    <td>{{ data.lab_category_id }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%"><strong>Komisi Dokter (default)</strong></td>
                    <td>Rp {{ data.doctor_commission }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%"><strong>Pendapatan Rumah Sakit (default)</strong></td>
                    <td>Rp {{ data.hospital_income }}</td>
                  </tr>
                </table>

                <button
                  @click="$router.push({ path: '/lab-procedure/edit/' + data.id })"
                  class="btn mx-1 btn-success"
                  v-if="manipulateBtn == true"><i class="far fa-fw fa-edit"></i> Edit</button>
                <button
                  @click="deleteData"
                  class="btn mx-1 btn-danger"
                  v-if="manipulateBtn == true"><i class="far fa-fw fa-trash-alt"></i> Hapus</button>
              </div>

            </div>
          </template>
        </Card>
      </div>

      <div class="col-12">
        <Card title="Komisi Dokter">
          <template v-slot:toolbar>
            <b-button
              squared
              variant="success"
              v-if="manipulateBtn == true"
              @click="$router.push({ path: '/payment-category/add-doctor-commision/' + data.id })">Tambah</b-button>
          </template>
          <template v-slot:body>

            <!-- Filter -->
            <div class="row align-items-center justify-content-end mt-n3">
              <div class="col-md-3 col-12">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    type="text"
                    v-model="filter.doctor_name"
                    placeholder="Cari Berdasar Dokter"
                    @keyup="fiterByDoctor"></b-form-input>
                </b-input-group>
              </div>
            </div>

            <!-- Table -->
            <b-table
              striped
              hover
              responsive
              class="mt-3"
              :items="items"
              :fields="fields">
              <template #cell(hospital_income)="data">
                Rp {{ data.item.hospital_income }}
              </template>
              <template #cell(doctor_commission)="data">
                Rp {{ data.item.doctor_commission }}
              </template>
              <template #cell(actions)="data">
                <b-button
                  v-b-modal.modal-edit-commission
                  variant="success"
                  v-b-tooltip.hover
                  @click="btnEditCommissionOnClick(data.item)"
                  title="Edit"
                  class="btn-sm mr-1"
                  v-if="manipulateBtn == true"><i class="fas fa-edit px-0"></i></b-button>
                <b-button
                  variant="danger"
                  v-b-tooltip.hover
                  @click="btnDeleteCommissionOnClick(data.item.id)"
                  title="Hapus"
                  class="btn-sm"
                  v-if="manipulateBtn == true"><i class="fas fa-trash px-0"></i></b-button>
              </template>
            </b-table>
            <b-pagination
              v-if="items.length != 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
              @page-click="pageOnClick"
              class="mt-4"></b-pagination>
          </template>
        </Card>
      </div>

    </div>

    <!-- Modal Edit Commission -->
    <b-modal
      id="modal-edit-commission"
      title="Ubah Komisi Dokter"
      @ok="modalOnSubmit">
      <b-form
        id="form-edit-commission"
        @submit="formOnSubmit">
        <!-- doctor commission -->
        <b-form-group
          id="input-group-doctor-commission"
          :label="`Komisi Dokter (${isPercent ? '%' : 'Rp'}):`"
          label-for="input-doctor-commission">
          <b-input-group>
            <template #prepend>
              <b-input-group-text
                squared
                @click="btnIsPercentToggle"
                class="bg-light-primary font-weight-bold text-primary">{{ isPercent ? '%' : 'Rp' }}</b-input-group-text>
            </template>
            <b-form-input
              id="input-doctor-commission"
              :readonly="formatedPrice == 0"
              @keyup="doctorCommisionOnKeyUp"
              v-model="formatedDoctorCommission"
              placeholder="Komisi Dokter..."></b-form-input>
          </b-input-group>
          <small class="text-danger">{{ error.doctor_commission }}</small>
        </b-form-group>

        <!-- hospital income -->
        <b-form-group
          id="input-group-hospital-income"
          :label="`Pendapatan Rumah Sakit (${isPercent ? '%' : 'Rp'}):`"
          label-for="input-hospital-income">
          <b-input-group>
            <template #prepend>
              <b-input-group-text
                squared
                @click="btnIsPercentToggle"
                class="bg-light-primary font-weight-bold text-primary">{{ isPercent ? '%' : 'Rp' }}</b-input-group-text>
            </template>
            <b-form-input
              id="input-hospital-income"
              :readonly="formatedPrice == 0"
              @keyup="hospitalIncomeOnKeyUp"
              v-model="formatedHospitalIncome"
              placeholder="Pendapatan Rumah Sakit..."></b-form-input>
          </b-input-group>
          <small class="text-danger">{{ error.hospital_income }}</small>
        </b-form-group>
      </b-form>
    </b-modal>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import { debounce } from "@/core/modules/Helper.js"
import module from '@/core/modules/CrudModule.js'
import validation from '@/core/modules/ValidationModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  data() {
    return {
      // Data
      data: {
        name: "",
        price: "",
        description: "",
        lab_category_id: "",
        doctor_commission: "",
        hospital_income: "",
      },
      // Filter
      filter: {
        doctor_name: ''
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Doctor Commision Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "doctor_name",
          label: "Dokter",
          sortable: true,
        },
        {
          key: "polyclinic_name",
          label: "Poliklinik",
          sortable: true,
        },
        {
          key: "hospital_income",
          label: "Pendapatan Rumah Sakit",
          sortable: true,
        },
        {
          key: "doctor_commission",
          label: "Komisi Dokter",
          sortable: true,
        },
        { key: 'actions', label: 'Aksi' }
      ],
      // Doctor Commision Table Items
      items: [],
      // Error
      error: {
        hospital_income: '',
        doctor_commission: ''
      },
      // Other
      selectedItem: {
        doctor_commission: '',
        hospital_income: '',
        price: ''
      },
      imgLoaded: false,
      totalPercentage: 100,
      isPercent: false,
      // access management
      manipulateBtn: false,
    }
  },

  components: {
    Card,
  },

  computed: {
    formatedPrice: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ''), 10)
        if (!Number.isNaN(newValue)) {
          this.selectedItem.price = newValue.toLocaleString('id-ID')
        } else {
          this.selectedItem.price = ''
        }
      },
      get() {
        return this.selectedItem.price
      }
    },

    formatedDoctorCommission: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ''), 10)
        if (!Number.isNaN(newValue)) {
          this.selectedItem.doctor_commission = newValue.toLocaleString('id-ID')
        } else {
          this.selectedItem.doctor_commission = ''
        }
      },
      get() {
        return this.selectedItem.doctor_commission
      }
    },

    formatedHospitalIncome: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ''), 10)
        if (!Number.isNaN(newValue)) {
          this.selectedItem.hospital_income = newValue.toLocaleString('id-ID')
        } else {
          this.selectedItem.hospital_income = ''
        }
      },
      get() {
        return this.selectedItem.hospital_income
      }
    }
  },

  methods: {

    onImageLoad() {
      this.imgLoaded = true
    },

    async deleteData() {
      let result = await module.delete('lab-procedures/' + this.$route.params.id)
      // If Deleted
      if (result) {
        // Redirect To List
        this.$router.push('/lab-procedure/list')
      }
    },

    btnEditCommissionOnClick(item) {
      for (const [key, value] of Object.entries(item)) {
        this.selectedItem[key] = value
      }
      this.selectedItem.price = parseInt(this.data.price).toLocaleString('id-ID')
    },

    async btnDeleteCommissionOnClick(id) {
      // Delete Data
      let result = await module.delete('lab-procedure-x-doctor/' + id)
      // If Deleted
      if (result) {
        this.pagination()
      }
    },

    btnIsPercentToggle() {
      this.error.doctor_commission = ''
      this.error.hospital_income = ''
      this.totalPercentage = 100
      this.selectedItem.doctor_commission = ''
      this.selectedItem.hospital_income = ''
      this.isPercent ? this.isPercent = false : this.isPercent = true
    },

    doctorCommisionOnKeyUp() {
      if (this.formatedPrice != 0) {
        if (this.isPercent) {
          this.totalPercentage = 100
          this.totalPercentage -= this.selectedItem.doctor_commission
          this.selectedItem.hospital_income = this.totalPercentage
        } else {
          let share = parseInt(this.selectedItem.price.replace(/\D/g, ''), 10)
          share -= parseInt(this.selectedItem.doctor_commission.replace(/\D/g, ''), 10)
          this.selectedItem.hospital_income = !isNaN(share) ? share.toLocaleString('id-ID') : ''
        }
      }
    },

    hospitalIncomeOnKeyUp() {
      if (this.formatedPrice != 0) {
        if (this.isPercent) {
          this.totalPercentage = 100
          this.totalPercentage -= this.selectedItem.hospital_income
          this.selectedItem.doctor_commission = this.totalPercentage
        } else {
          let share = parseInt(this.selectedItem.price.replace(/\D/g, ''), 10)
          share -= parseInt(this.selectedItem.hospital_income.replace(/\D/g, ''), 10)
          this.selectedItem.doctor_commission = !isNaN(share) ? share.toLocaleString('id-ID') : ''
        }
      }
    },

    modalOnSubmit(evt) {
      evt.preventDefault()
      this.formOnSubmit()
    },

    async get() {
      this.data = await module.get('lab-procedures/' + this.$route.params.id)
      // If Data Not Found
      if (this.data == null) {
        // Redirect To List
        this.$router.push('/lab-procedure/list')
      }
    },

    pageOnClick(page) {
      this.currentPage = page
      this.pagination()
    },

    fiterByDoctor() {
      debounce(() => { this.pagination() }, 500)
    },

    async pagination() {
      let filterParams = `&doctor_name=${this.filter.doctor_name}`
      let response = await module.paginate(`lab-procedure-x-doctor/get-by-lab-procedure/${this.$route.params.id}`, `?page_size=${this.perPage}&page=${this.currentPage}${filterParams}`)
      let pagination = response.meta.pagination
      this.items = response.data
      this.totalRows = pagination.total
    },

    async formOnSubmit() {
      if (!this.isPercent) {
        let intPrice = parseInt(this.selectedItem.price.replace(/\D/g, ''), 10)
        let intDoctorCommission = parseInt(this.selectedItem.doctor_commission.replace(/\D/g, ''), 10)
        let intHospitalIncome = parseInt(this.selectedItem.hospital_income.replace(/\D/g, ''), 10)
        if (intPrice < intDoctorCommission || intPrice < intHospitalIncome) {
          Swal.fire('Gagal', 'Komisi Dokter / Pendapatan Rumah Sakit tidak boleh melebihi Harga', 'warning')
        } else {
          this.actualFormSubmit()
        }
      } else {
        this.actualFormSubmit()
      }
    },

    async actualFormSubmit() {
      this.selectedItem['_method'] = 'put'
      this.selectedItem['is_percent'] = this.isPercent
      // Make Request
      let response = await module.submit(this.selectedItem, `lab-procedure-x-doctor/${this.selectedItem.id}`)
      // Check Response
      if (response.state == 'error') {
        // Validation Error
        this.error = validation.setValidationError(this.error, response.error.errors)
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, 'success')
        this.$bvModal.hide('modal-edit-commission')
        this.pagination()
      }
    },

    // access management
    async setActiveMenu() {

      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)

      let a
      for (a = 0; a < access_right.length; a++) {
        console.log("looping")

        if (access_right[a] == "1102") {
          this.manipulateBtn = true
        }

      }
    },

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Pemeriksaan Lab", route: "" },
      { title: "Daftar Biaya Laboratorium", route: "/lab-procedure/list" },
      { title: "Detail" },
    ])
    // Get Data
    this.get()
    this.pagination()
    this.setActiveMenu()
  },

}

</script>

<style scoped>
.bg-light-primary:hover {
  background-color: #e1f0ffa6 !important;
}
</style>